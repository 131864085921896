<template>
  <c-box id="competition">
    <!--   :isLocked="!getCurrentPlan.access.comparison" -->
    <TaskCard
      title="Competition"
      :description="''"
      :hasComments="true"
      :hasFooter="true"
      :hasProgress="true"
      :canAssign="true"
      :hasImportance="true"
      :hasNotification="true"
      :hasVisibility="true"
      :hasAssumption="false"
      :hasMounted="hasMounted"
      :disabled="disabled"
      :taskData="competitionData"
      :accessKey="'competition_assignments'"
      :accessFn="updateAccess"
      @update="updateData"
      :hasAccess="hasAccess"
      :visibilityKey="'competition_visibilities'"
      :visibilityFn="updateVisibility"
      :isUpdatingMetaData="isUpdatingMetadata"
      explanationKey="competitor"
    >
      <ContentLoader
        v-if="isFetchingData"
        viewBox="0 0 250 130"
        primaryColor="#ddd"
      >
        <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="20" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="40" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
      </ContentLoader>
      <CompetitionGrid
        v-else
        :disabled="disabled"
        :competitionData="competitionData"
        @update="updateData"
      />
    </TaskCard>
  </c-box>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

import TaskCard from './../TaskCard';
import CompetitionGrid from './../competition/CompetitionGrid.vue';

import competitionMixin from '@/mixins/competition.js';
import { mapGetters } from 'vuex';

export default {
  mixins: [competitionMixin],
  props: ['disabled'],
  components: {
    TaskCard,
    CompetitionGrid,
    ContentLoader,
  },
  data() {
    return {
      competitionType: 'competitor',
      hasMounted: false,
    };
  },
  computed: {
    ...mapGetters('subscription', ['getCurrentPlan']),
  },
};
</script>

<style></style>
