<template>
  <div class="task-card">
    <c-box
      v-if="isLocked && !hasPremiumAccess"
      py="3"
      px="8"
      bg="#f4f6fc"
      d="flex"
      align-items="center"
      justify-content="space-between"
    >
      <c-box mb="4">
        <c-box d="flex" align-items="center" mb="2">
          <svg
            v-chakra="{
              w: '15px',
              h: '15px',
              fill: '#ef5323',
            }"
          >
            <use href="@/assets/icons/icon-lock.svg#icon-lock"></use>
          </svg>
          <c-text ml="2" fontSize=".9em" color="#ef5323">Premium</c-text>
        </c-box>

        <c-text color="gray.400" fontSize=".8em" mb="2"
          >Your current plan is freemium.
          {{
            !isCompanyOwner && !isCompanyAdmin
              ? 'Please contact your company owner/admin to unlock this feature'
              : 'Upgrade to unlock this feature'
          }}
        </c-text>
        <c-box d="flex" align-items="center">
          <c-text px="3" py="1" color="black" bg="white" fontSize=".8em"
            >Freemium</c-text
          >
          <c-text mx="2" fontSize=".8em">→</c-text>
          <c-text px="3" py="1" color="white" bg="#ef5323" fontSize=".8em"
            >Premium</c-text
          >
        </c-box>
      </c-box>
      <c-button
        v-if="isCompanyAdmin || isCompanyOwner"
        @click="$router.push({ name: 'Subscription' })"
        size="md"
        px="8"
        variant-color="vc-orange"
      >
        Upgrade
      </c-button>
    </c-box>
    <div
      class="card"
      :class="{ 'card--disabled': isLocked && !hasPremiumAccess }"
    >
      <div
        class="card__disabled-wrapper"
        v-if="isLocked && !hasPremiumAccess"
      />
      <div class="card__header">
        <div class="card__header__top">
          <div
            class="card__header__top__left"
            :class="{
              'card__header__top__left--full':
                (!canAssign && !hasNotification) || isShareView,
            }"
          >
            <c-heading
              class="card__title"
              :fontSize="size === 'small' ? 'lg' : '2xl'"
              :order="size === 'small' ? '2' : '1'"
              fontWeight="600"
              as="h4"
              display="flex"
            >
              {{ title }}
            </c-heading>
            <EditableProgress
              :typeCard="title"
              ref="editableProgress"
              :order="size === 'small' ? '1' : '2'"
              v-chakra="{
                marginBottom: `${size === 'small' ? '10px' : '0'}`,
                marginRight: '10px',
              }"
              :value="taskData.progress"
              @input="(value) => handleProgressUpdate(value)"
              v-if="hasProgress && !disabled && hasAccess"
              :disabled="isShareView"
            />
          </div>
          <div class="card__header__top__right">
            <template v-if="!disabled && hasAccess && !isShareView">
              <AssignMembers
                @memberSelect="onMemberSelect"
                :members="assignedMembers"
                v-if="canAssign"
              />
            </template>
          </div>
        </div>
        <c-text class="card__description" v-if="!disabled">
          <slot name="description">{{ description }}</slot>
        </c-text>
      </div>
      <div class="card__tags" v-if="!disabled && hasAccess && !isShareView">
        <ImportanceSelect
          v-if="hasImportance"
          :value="taskData.priority"
          @input="(value) => $emit('update', { key: 'priority', value })"
          :disabled="disabled"
        />
        <c-box :id="assumptionId">
          <AssumptionSelect
            v-if="hasAssumption"
            :value="taskData.assumption"
            :descriptionData="taskData.description"
            ref="assumptionSelect"
            :title="title"
            @input="(value) => updateAssumptionData(value)"
            @validate="(value) => $emit('update', { key: 'assumption', value })"
            :disabled="disabled"
          />
        </c-box>
      </div>
      <div class="card__content">
        <slot v-if="hasAccess" />
        <c-text v-else>
          Sorry you do not have access to view this information. <br />
          Contact business owner.
        </c-text>
      </div>
      <div
        v-if="hasFooter && !disabled && hasAccess && !isShareView"
        class="card__footer"
      >
        <c-link
          display="flex"
          w="max-content"
          mb="5"
          @click="openExplanationDrawer"
          color="vc-orange.400"
        >
          Explanation
        </c-link>
        <c-stack
          :spacing="7"
          is-inline
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <!-- <c-stack mr="3em" v-if="hasNotification" :spacing="2">
          <c-heading fontSize="sm" color="#495057" as="h6">
            Notifications
          </c-heading>
          <c-popover :placement="'bottom'">
            <c-popover-trigger>
              <c-link
                display="flex"
                fontWeight="500"
                color="#798892"
                fontSize="xs"
              >
                <c-text as="span" mr="0.3rem"> One week </c-text>
                <svg
                  v-chakra="{
                    w: '15px',
                    h: '15px',
                    fill: '#ef5323',
                  }"
                >
                  <use href="@/assets/icons/edit-fill.svg#edit"></use>
                </svg>
              </c-link>
            </c-popover-trigger>
            <c-popover-content
              z-index="4"
              maxWidth="150px"
              maxHeight="200px"
              overflowY="scroll"
            >
              <c-popover-body
                paddingTop="5px"
                paddingBottom="5px"
                paddingInline="0"
              >
                <c-list>
                  <c-list-item
                    fontSize="xs"
                    paddingTop="5px"
                    paddingBottom="5px"
                    paddingLeft="10px"
                    fontWeight="600"
                    color="#495057"
                  >
                    One day
                  </c-list-item>
                  <c-list-item
                    fontSize="xs"
                    paddingTop="5px"
                    paddingBottom="5px"
                    paddingLeft="10px"
                    fontWeight="600"
                    color="#495057"
                  >
                    One week
                  </c-list-item>
                  <c-list-item
                    fontSize="xs"
                    paddingTop="5px"
                    paddingBottom="5px"
                    paddingLeft="10px"
                    fontWeight="600"
                    color="#495057"
                  >
                    Two weeks
                  </c-list-item>
                  <c-list-item
                    fontSize="xs"
                    paddingTop="5px"
                    paddingBottom="5px"
                    paddingLeft="10px"
                    fontWeight="600"
                    color="#495057"
                  >
                    One Month
                  </c-list-item>
                  <c-list-item
                    fontSize="xs"
                    paddingTop="10px"
                    paddingBottom="10px"
                    paddingLeft="10px"
                    color="#ef5323"
                    fontWeight="600"
                    borderTopWidth="1px"
                    borderTopColor="#7070702c"
                  >
                    Set time
                  </c-list-item>
                  <c-list-item
                    fontSize="xs"
                    paddingTop="10px"
                    paddingBottom="10px"
                    paddingLeft="10px"
                    color="#ee2f1f"
                    fontWeight="600"
                    borderTopWidth="1px"
                    borderTopColor="#7070702c"
                  >
                    Turn off
                  </c-list-item>
                </c-list>
              </c-popover-body>
            </c-popover-content>
          </c-popover>
        </c-stack> -->
          <VisibilitySelect
            :members="visibilityMembers"
            @selected="onVisibilitySelected"
            v-if="hasVisibility"
          />
          <div style="display: flex; align-items: center">
            <c-link
              v-if="hasComments"
              @click="openCommentDrawer"
              style="margin-right: 0.75em"
            >
              <c-spinner
                v-if="isFetchingComments"
                color="blue.500"
                thickness="2px"
              />
              <svg
                v-else
                v-chakra="{
                  w: '1.75em',
                  h: '1.75em',
                  fill: '#798892',
                  cursor: 'pointer',
                }"
              >
                <use href="@/assets/icons/comment-fill.svg#comment"></use>
              </svg>
            </c-link>
            <c-text
              fontSize="sm"
              fontWeight="500"
              v-if="hasComments && !isFetchingComments && comments.length"
            >
              {{ comments.length }} messages
            </c-text>
          </div>
          <span @click="openValidationModal = true" v-if="hasValidation">
            <c-tag variant-color="cyan" size="sm">
              <c-tag-label style="cursor: pointer">View Validation</c-tag-label>
              <c-tag-icon icon="check" size="12px" />
            </c-tag>
          </span>

          <c-spinner
            v-if="isUpdatingMetaData"
            color="blue.500"
            thickness="2px"
          />
        </c-stack>
      </div>
      <c-drawer
        :isOpen="isCommentDrawerOpen"
        placement="right"
        :on-close="closeCommentDrawer"
        ref="commentDrawer"
      >
        <c-drawer-overlay />
        <c-drawer-content backgroundColor="#f4f6fc" maxWidth="35rem">
          <c-stack is-inline :spacing="8" p="8">
            <!-- <c-stack v-if="hasNotification" :spacing="2">
            <c-heading fontSize="sm" color="#495057" as="h6">
              Notifications
            </c-heading>
            <c-popover :placement="'bottom'">
              <c-popover-trigger>
                <c-link
                  display="flex"
                  fontWeight="500"
                  color="#798892"
                  fontSize="xs"
                >
                  <c-text as="span" mr="0.3rem"> One week </c-text>
                  <svg
                    v-chakra="{
                      w: '15px',
                      h: '15px',
                      fill: '#ef5323',
                    }"
                  >
                    <use href="@/assets/icons/edit-fill.svg#edit"></use>
                  </svg>
                </c-link>
              </c-popover-trigger>
              <c-popover-content
                z-index="4"
                maxWidth="150px"
                maxHeight="200px"
                overflowY="scroll"
              >
                <c-popover-body
                  paddingTop="5px"
                  paddingBottom="5px"
                  paddingInline="0"
                >
                  <c-list>
                    <c-list-item
                      fontSize="xs"
                      paddingTop="5px"
                      paddingBottom="5px"
                      paddingLeft="10px"
                      fontWeight="600"
                      color="#495057"
                    >
                      One day
                    </c-list-item>
                    <c-list-item
                      fontSize="xs"
                      paddingTop="5px"
                      paddingBottom="5px"
                      paddingLeft="10px"
                      fontWeight="600"
                      color="#495057"
                    >
                      One week
                    </c-list-item>
                    <c-list-item
                      fontSize="xs"
                      paddingTop="5px"
                      paddingBottom="5px"
                      paddingLeft="10px"
                      fontWeight="600"
                      color="#495057"
                    >
                      Two weeks
                    </c-list-item>
                    <c-list-item
                      fontSize="xs"
                      paddingTop="5px"
                      paddingBottom="5px"
                      paddingLeft="10px"
                      fontWeight="600"
                      color="#495057"
                    >
                      One Month
                    </c-list-item>
                    <c-list-item
                      fontSize="xs"
                      paddingTop="10px"
                      paddingBottom="10px"
                      paddingLeft="10px"
                      color="#ef5323"
                      fontWeight="600"
                      borderTopWidth="1px"
                      borderTopColor="#7070702c"
                    >
                      Set time
                    </c-list-item>
                    <c-list-item
                      fontSize="xs"
                      paddingTop="10px"
                      paddingBottom="10px"
                      paddingLeft="10px"
                      color="#ee2f1f"
                      fontWeight="600"
                      borderTopWidth="1px"
                      borderTopColor="#7070702c"
                    >
                      Turn off
                    </c-list-item>
                  </c-list>
                </c-popover-body>
              </c-popover-content>
            </c-popover>
          </c-stack> -->
            <VisibilitySelect
              :members="visibilityMembers"
              @selected="onVisibilitySelected"
              v-if="hasVisibility"
            />
          </c-stack>
          <c-drawer-close-button @click="isCommentDrawerOpen = false" />

          <c-drawer-body
            d="flex"
            flex-direction="column"
            px="8"
            pb="0"
            maxHeight="85%"
            overflowY="scroll"
          >
            <c-box class="history" maxHeight="20%" h="20%">
              <c-heading as="h4" fontSize="sm" color="#444444"
                >History</c-heading
              >
              <c-list my="3" maxHeight="calc(100% - 10px)" overflowY="scroll">
                <c-list-item
                  marginBottom="10px"
                  v-for="action in history"
                  :key="action.id"
                >
                  <c-flex
                    fontSize="xs"
                    fontWeight="500"
                    justify="space-between"
                    color="#929ba1"
                  >
                    <template
                      v-if="action.data.set && action.data.set.description"
                    >
                      <span>
                        {{ action.user.firstname }}
                        {{ action.user.lastname }} edited
                        {{ titlecase(action.component.split('_').join(' ')) }}
                      </span>
                    </template>
                    <template
                      v-if="action.data.set && action.data.set.progress"
                    >
                      {{ action.user.firstname }}
                      {{ action.user.lastname }} set
                      {{ titlecase(action.component.split('_').join(' ')) }}
                      to
                      {{
                        titlecase(
                          action.data.set.progress
                            .toLowerCase()
                            .split('_')
                            .join(' ')
                        )
                      }}
                    </template>
                    <template
                      v-if="action.data.set && action.data.set.priority"
                    >
                      <span
                        v-chakra="{
                          display: 'flex',
                        }"
                      >
                        {{ action.user.firstname }}
                        {{ action.user.lastname }} changed Priority to:
                        <c-text
                          ml="2"
                          :color="
                            importanceLevels[action.data.set.priority].color
                          "
                          fontWeight="600"
                        >
                          {{ importanceLevels[action.data.set.priority].name }}
                        </c-text>
                      </span>
                    </template>
                    <template v-if="action.data.object">
                      <span
                        v-chakra="{
                          display: 'flex',
                        }"
                      >
                        {{ action.user.firstname }}
                        {{ action.user.lastname }} created
                        {{ titlecase(action.component.split('_').join(' ')) }}
                      </span>
                    </template>
                    <span>
                      {{ action.createdAt | moment('DD MMM. YYYY') }} at
                      {{ action.createdAt | moment('hh:mm a') }}
                    </span>
                  </c-flex>
                </c-list-item>
                <!-- <c-list-item marginBottom="10px">
                <c-flex
                  fontSize="xs"
                  fontWeight="500"
                  justify="space-between"
                  color="#929ba1"
                >
                  <span
                    v-chakra="{
                      display: 'flex',
                    }"
                  >
                    Victoria Arnold created Vision
                  </span>
                  <span>Feb 20 2020 at 6:01 pm</span>
                </c-flex>
              </c-list-item> -->
              </c-list>
            </c-box>
            <div
              class="comments"
              v-chakra="{
                mt: '12',
                h: '78%',
                mb: '10',
              }"
            >
              <c-heading as="h4" fontSize="sm" color="#444444">
                Messages
              </c-heading>
              <c-list
                mt="5"
                maxHeight="calc(100% - 50px)"
                overflowY="scroll"
                scrollBehavior="smooth"
                ref="commentList"
              >
                <transition-group name="fade">
                  <c-list-item
                    w="80%"
                    h="100%"
                    :marginBottom="ix < comments.length - 1 ? '20px' : ''"
                    v-for="(comment, ix) in comments"
                    :key="comment.id"
                    :ml="comment.user.id !== user.id ? '' : 'auto'"
                  >
                    <c-flex
                      :direction="
                        comment.user.id !== user.id ? 'row' : 'row-reverse'
                      "
                      maxWidth="100%"
                    >
                      <c-avatar
                        size="md"
                        :name="comment.user.name"
                        :src="comment.user.profilePhoto"
                        borderWidth="0px"
                      />
                      <c-stack
                        :spacing="2"
                        flex="1"
                        maxWidth="calc(100% - 75px)"
                        :ml="comment.user.id !== user.id ? '6' : '0'"
                        :mr="comment.user.id !== user.id ? '0' : '6'"
                      >
                        <c-flex
                          justify="space-between"
                          :direction="
                            comment.user.id !== user.id ? 'row' : 'row-reverse'
                          "
                        >
                          <c-flex
                            direction="row"
                            align="center"
                            position="relative"
                          >
                            <c-text
                              color="vc-orange.500"
                              fontSize="xs"
                              fontWeight="600"
                            >
                              <template v-if="comment.user.id !== user.id">
                                {{ comment.user.firstname }}
                                {{ comment.user.lastname }}
                              </template>
                              <template v-else> You </template>
                              <small v-if="comment.isEdited"> (edited)</small>
                            </c-text>
                            <c-popover
                              placement="bottom"
                              v-if="comment.user.id === user.id"
                            >
                              <c-popover-trigger cursor="pointer">
                                <c-box>
                                  <svg
                                    v-chakra="{
                                      width: '10px',
                                      height: '16px',
                                      ml: '2',
                                    }"
                                  >
                                    <use
                                      href="@/assets/icons/dots-horizontal.svg#dots"
                                    ></use>
                                  </svg>
                                </c-box>
                              </c-popover-trigger>
                              <c-popover-content width="150px">
                                <c-popover-body>
                                  <c-list
                                    v-chakra="{
                                      bg: 'white',
                                    }"
                                  >
                                    <c-list-item
                                      ><c-box
                                        @click="onEditComment(comment, ix)"
                                        cursor="pointer"
                                        w="100%"
                                        mb="3"
                                      >
                                        Edit
                                      </c-box></c-list-item
                                    >
                                    <c-list-item
                                      ><c-box
                                        @click="onDeleteComment(comment, ix)"
                                        cursor="pointer"
                                        w="100%"
                                      >
                                        Delete
                                      </c-box></c-list-item
                                    >
                                  </c-list>
                                </c-popover-body>
                              </c-popover-content>
                            </c-popover>
                          </c-flex>
                          <c-text
                            color="#a6a9ad"
                            fontWeight="300"
                            fontSize="10px"
                          >
                            {{ comment.createdAt | moment('from') }}
                          </c-text>
                        </c-flex>
                        <c-box
                          w="100%"
                          min-h="50px"
                          h="100%"
                          padding="10px"
                          borderRadius="16px"
                          :borderTopLeftRadius="
                            comment.user.id !== user.id ? '0px' : '16px'
                          "
                          :borderTopRightRadius="
                            comment.user.id !== user.id ? '16px' : '0px'
                          "
                          backgroundColor="#fff"
                          fontSize="sm"
                          v-html="comment.message"
                          class="message-box"
                        >
                        </c-box>
                      </c-stack>
                    </c-flex>
                  </c-list-item>
                </transition-group>
              </c-list>
            </div>

            <c-box position="absolute" v-if="isEditMode">
              <c-box
                @click="cancelEdit"
                cursor="pointer"
                px="3"
                py="1"
                color="error"
                d="inline-block"
                roundedTop="lg"
              >
                <c-text color="vc-orange.400" fontSize="sm">
                  Cancel Edit
                </c-text>
              </c-box>
            </c-box>
            <c-flex
              align="center"
              w="100%"
              maxWidth="100%"
              py="2"
              pl="2"
              pr="3"
              bg="#fff"
            >
              <c-box
                flexBasis="calc(100% - 60px)"
                flexGrow="0"
                maxWidth="calc(100% - 60px)"
              >
                <quill-editor
                  class="editor"
                  ref="textEditor"
                  v-model="comment"
                  :options="editorOptions"
                />
              </c-box>
              <c-box w="50px">
                <c-spinner
                  v-if="isSavingComment"
                  color="blue.500"
                  thickness="3px"
                />
                <c-button
                  px="0"
                  borderRadius="50%"
                  variant-color="blue"
                  variant="ghost"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  v-else
                  @click="!isEditMode ? addComment() : editComment()"
                >
                  <svg
                    v-chakra="{
                      w: '35px',
                      h: '35px',
                    }"
                  >
                    <use href="@/assets/icons/icon-send.svg#send"></use>
                  </svg>
                </c-button>
              </c-box>
            </c-flex>
          </c-drawer-body>
        </c-drawer-content>
      </c-drawer>
      <c-drawer
        :isOpen="isExplanationDrawerOpen"
        placement="right"
        :on-close="closeExplanationDrawer"
        ref="explanationDrawer"
      >
        <c-drawer-overlay />
        <c-drawer-content backgroundColor="#f4f6fc" maxWidth="35rem">
          <c-drawer-close-button />
          <c-drawer-body overflowY="scroll">
            <c-stack :spacing="3">
              <c-flex justify="space-between" p="8">
                <c-heading fontSize="sm" color="vc-orange.500">
                  Explanation
                </c-heading>
                <!-- <c-link fontSize="sm" color="#1a86d0">
                Watch Explanation Video
              </c-link> -->
              </c-flex>
              <c-box px="8">
                <c-text v-html="explanation.content"></c-text>
              </c-box>
            </c-stack>
          </c-drawer-body>
        </c-drawer-content>
      </c-drawer>
    </div>
    <share-content
      :isShareOpen="isShareOpen"
      @close="onShareClick"
      :url="``"
      :shareTitle="'Share your progress on ' + title"
      :postTitle="'Hi, just completed my goal on VibrantCreaor'"
      :postDescription="'Click the link to squash your goals too!'"
    />
    <ValidationDrawer
      v-if="openValidationModal"
      :title="title"
      :description="taskData.description"
      @close="openValidationModal = false"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import EditableProgress from './EditableProgress.vue';
import AssignMembers from './AssignMembers.vue';
import ImportanceSelect from './ImportanceSelect.vue';
import AssumptionSelect from './AssumptionSelect.vue';
import ShareContent from './../../../App/components/ShareContent.vue';
import ValidationDrawer from './ValidationDrawer.vue';

import {
  addCommentToComponent,
  updateComment,
  getComponentComments,
  subscribeToComments,
} from '@/services/user';
import VisibilitySelect from './VisibilitySelect.vue';
import { getExplanation } from '@/services/common';
import { getComponentActivity } from '@/services/company';
import cloneDeep from 'lodash.clonedeep';
import { titlecase } from '@/helpers/utils';
import 'quill-mention/dist/quill.mention.min.css';
import 'quill-mention';
import '@/helpers/editor/blots/mention';
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
    hasNotification: {
      type: Boolean,
    },
    hasVisibility: {
      type: Boolean,
    },
    hasComments: {
      type: Boolean,
    },
    canAssign: {
      type: Boolean,
    },
    hasProgress: {
      type: Boolean,
    },
    hasFooter: {
      type: Boolean,
    },
    hasImportance: {
      type: Boolean,
    },
    hasAssumption: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
    },
    taskData: {
      type: Object,
      default: () => ({}),
    },
    accessFn: {
      type: Function,
      default: () => {},
    },
    accessKey: {
      type: String,
      default: '',
    },
    visibilityKey: {
      type: String,
      default: '',
    },
    visibilityFn: {
      type: Function,
      default: () => {},
    },
    hasAccess: {
      type: Boolean,
      default: true,
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
    shareOnDone: {
      type: Boolean,
      default: true,
    },
    isUpdatingMetaData: {
      type: Boolean,
      default: false,
    },
    hasMounted: {
      type: Boolean,
      default: false,
    },
    explanationKey: {
      type: String,
      default: '',
    },
    assumptionId: {
      type: String,
      default: '',
    },
  },
  components: {
    EditableProgress,
    AssignMembers,
    ImportanceSelect,
    AssumptionSelect,
    VisibilitySelect,
    ShareContent,
    ValidationDrawer,
  },
  data() {
    return {
      openValidationModal: false,
      isShareOpen: false,
      isCommentDrawerOpen: false,
      isExplanationDrawerOpen: false,
      comments: [],
      comment: null,
      isSavingComment: false,
      isFetchingComments: false,
      isEditMode: false,
      explanation: {},
      history: [],
      currentEditIndex: null,
      importanceLevels: {
        urgent: {
          name: 'Urgent',
          value: 'urgent',
          color: '#ee2f1f',
          colorRgba: 'rgba(238, 48, 31, 0.1)',
        },
        high: {
          name: 'High',
          value: 'high',
          color: '#ef5323',
          colorRgba: 'rgba(239, 83, 35, 0.1)',
        },
        normal: {
          name: 'Normal',
          color: '#ef7923',
          value: 'normal',
          colorRgba: 'rgba(239, 121, 35, 0.1)',
        },
        low: {
          name: 'Low',
          value: 'low',
          color: '#37d5aa',
          colorRgba: 'rgba(55, 213, 170, 0.1)',
        },
        clear: {
          name: 'Clear',
          value: 'clear',
          color: '#56b5db',
          colorRgba: 'rgba(86, 181, 219, 0.1)',
        },
      },
      editorOptions: {
        placeholder: `Comment`,
        modules: {
          toolbar: false,
          clipboard: {
            matchers: [[Node.TEXT_NODE, this.linkMatcher]],
          },
          mention: {
            maxChars: 31,
            isolateCharacter: true,
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ['@'],
            blotName: 'vc-mention',
            source: async (searchTerm, renderList) => {
              try {
                let matches = [];
                const searchRegex = new RegExp(searchTerm.trim(), 'i');
                matches = this.teamMembers
                  .filter(
                    (member) =>
                      searchRegex.test(member.firstname) ||
                      searchRegex.test(member.lastname)
                  )
                  .map((user) => {
                    return {
                      id: user.id,
                      value: `${user.firstname} ${user.lastname}`,
                    };
                  });
                renderList(matches, searchTerm);
              } catch (e) {
                console.log({ e });
                renderList([], searchTerm);
              }
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters('company', ['isCompanyOwner', 'isCompanyAdmin']),
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    ...mapState('company', {
      isShareView: (state) => state.isShareView,
      activeCompany: (state) => state.activeCompany,
    }),
    ...mapState({
      teamMembers: (state) =>
        state.company.members
          .filter((member) => member.isAccepted && member.user.isVerified)
          .map((member) => member.user),
    }),
    hasPremiumAccess() {
      return false;
    },
    assignedMembers() {
      if (!this.canAssign || !this.taskData[this.accessKey]) {
        return [];
      }
      return this.taskData[this.accessKey].map((assignment) => assignment.user);
    },
    visibilityMembers() {
      if (
        !this.hasVisibility ||
        !this.visibilityKey ||
        !this.taskData[this.visibilityKey]
      ) {
        return [];
      }
      return this.taskData[this.visibilityKey].map(
        (visibility) => visibility.user
      );
    },
    hasValidation() {
      return (
        this.title === 'Overall Value Proposition' ||
        this.title === 'Overall Solution' ||
        this.title === 'Overall Problem'
      );
    },
  },
  watch: {
    isCommentDrawerOpen() {
      if (this.isCommentDrawerOpen) {
        this.scrollToBottomOfComments();
      }
    },
    comments() {
      this.scrollToBottomOfComments();
    },
  },
  created() {
    if (!this.isShareView) {
      this.watchTaskData();
      this.getExplanation();
    }
  },
  methods: {
    titlecase,
    onShareClick() {
      this.isShareOpen = !this.isShareOpen;
    },
    updateAssumptionData(value) {
      this.$emit('update', {
        key: 'assumption',
        value: value.assumption,
      });
      this.$emit('update', {
        key: 'assumptionData',
        value: value.assumptionData,
      });
      if (value.assumption === 'verified') {
        if (this.shareOnDone && this.hasMounted) {
          this.onShareClick();
        }
      }
      if (value.assumption !== 'verified') {
        this.$emit('update', { key: 'progress', value: 'IN_PROGRESS' });
      }
    },
    openCommentDrawer() {
      this.isCommentDrawerOpen = true;
    },
    closeCommentDrawer() {
      this.isCommentDrawerOpen = false;
    },
    openExplanationDrawer() {
      this.isExplanationDrawerOpen = true;
    },
    closeExplanationDrawer() {
      this.isExplanationDrawerOpen = false;
    },
    handleKeyUp(e) {
      if (e.key === 'Enter' || e.code === 'Enter') {
        this.addComment();
      }
    },
    handleProgressUpdate(value) {
      if (this.hasAssumption) {
        if (value === 'DONE' && this.taskData.assumption !== 'verified') {
          this.$emit('update', { key: 'progress', value: 'IN_PROGRESS' });
          this.$refs.assumptionSelect?.setIsPremiseModal(true);
        } else {
          this.$emit('update', { key: 'progress', value });
          if (this.shareOnDone && value === 'DONE' && this.hasMounted) {
            this.onShareClick();
          }
        }
      } else {
        this.$emit('update', { key: 'progress', value });
        if (this.shareOnDone && value === 'DONE' && this.hasMounted) {
          this.onShareClick();
        }
      }
    },
    addComment() {
      if (this.comment && this.comment.trim()) {
        this.isSavingComment = true;
        const data = {
          userId: this.user.id,
          componentId: this.taskData.id,
          message: this.comment,
          type: this.taskData.type,
          comment_mentions: { data: this.getCommentMentions() },
        };
        addCommentToComponent(data)
          .then((res) => {
            this.isSavingComment = false;
            this.comments.push(res.data.insert_comment_one);
            this.comment = null;
          })
          .catch((e) => {
            this.isSavingComment = false;
            console.log({ e });
          });
      }
    },
    async onDeleteComment(comment, index) {
      this.comments.splice(index, 1);
      try {
        // await deleteComment(comment.id);
        await updateComment({
          id: comment.id,
          set: { isDeleted: true },
        });
      } catch (e) {
        this.comments.splice(index, 0, comment);
        this.$toast({
          title: 'Error!!!',
          description: `An error occurred while deleting comment, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    onEditComment(comment, index) {
      this.isEditMode = true;
      this.currentEditIndex = index;
      this.comment = comment.message;
    },
    cancelEdit() {
      this.isEditMode = false;
      this.comment = '';
    },
    async editComment() {
      this.isSavingComment = true;
      const oldComment = { ...this.comments[this.currentEditIndex] };
      this.comments.splice(this.currentEditIndex, 1, {
        ...oldComment,
        message: this.comment,
        isEdited: true,
      });
      try {
        await updateComment({
          id: oldComment.id,
          set: { message: this.comment, isEdited: true },
        });
        this.isSavingComment = false;
        this.cancelEdit();
      } catch (e) {
        this.comments.splice(this.currentEditIndex, 1, oldComment);
        this.isSavingComment = false;
        this.$toast({
          title: 'Error!!!',
          description: `An error occurred while editing comment, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    watchTaskData() {
      const unwatchTaskData = this.$watch('taskData', () => {
        this.getComments();
        this.getComponentActivity();
        unwatchTaskData();
      });
    },
    getComments() {
      this.isFetchingComments = true;
      getComponentComments({
        componentId: this.taskData.id,
        type: this.taskData.type,
      }).then((res) => {
        this.comments = res.data.comment;
        this.isFetchingComments = false;
        this.subscribeToComments();
      });
    },
    onMemberSelect(member) {
      this.accessFn(member.user);
    },
    onVisibilitySelected(members) {
      this.visibilityFn(members);
    },
    subscribeToComments() {
      subscribeToComments({
        componentId: this.taskData.id,
        userId: this.user.id,
        type: this.taskData.type,
      }).subscribe({
        next: (data) => {
          if (data.data.comment.length) {
            const newComment = data.data.comment[0];
            const commentId = this.comments.findIndex(
              (comment) => comment.id === newComment.id
            );
            if (commentId === -1) {
              this.comments.push({ ...newComment });
            }
          }
        },
        error(error) {
          console.error(error);
        },
      });
    },
    scrollToBottomOfComments() {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.$refs.commentList) {
            const commentListRef = this.$refs.commentList.$el;
            if (commentListRef) {
              commentListRef.scrollTop =
                commentListRef.scrollHeight - commentListRef.clientHeight;
            }
          }
        });
      });
    },
    getExplanation() {
      getExplanation(this.explanationKey).then((res) => {
        this.explanation = res.data.explanation[0] || {};
      });
    },
    getComponentActivity() {
      getComponentActivity({
        _and: {
          companyId: { _eq: this.activeCompany.id },
          component: { _eq: this.taskData.type },
        },
      }).then((res) => {
        this.history = cloneDeep(res.data.activity_logs);
      });
    },
    linkMatcher(node, delta) {
      const regex = /https?:\/\/[^\s]+/g;
      if (typeof node.data !== 'string') return;
      const matches = node.data.match(regex);
      if (matches && matches.length > 0) {
        const ops = [];
        let str = node.data;
        matches.forEach(function (match) {
          const split = str.split(match);
          const beforeLink = split.shift();
          ops.push({ insert: beforeLink });
          ops.push({ insert: match, attributes: { link: match } });
          str = split.join(match);
        });
        ops.push({ insert: str });
        delta.ops = ops;
      }
      return delta;
    },
    getCommentMentions() {
      const mentions = Array.from(
        this.$refs.textEditor.$el.querySelectorAll(
          '.mention[data-denotation-char="@"]'
        )
      ).map((el) => el.dataset);
      return mentions.map((mention) => {
        return {
          userId: mention.id,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.task-card {
  box-shadow: 0 3px 6px rgba(186, 186, 186, 0.16),
    0 0px 6px rgba(186, 186, 186, 0.16);
  border-radius: 8px;

  &.v-tour__target--highlighted {
    box-shadow: 0 3px 20px rgba(26, 131, 201, 0.418);
  }
}
.card {
  @apply w-full h-full flex flex-col;
  min-height: 250px;
  position: relative;
  background-color: #fff;

  &--disabled {
    filter: blur(0.5px);
    -webkit-filter: blur(0.5px);
    -moz-filter: blur(0.5px);
    opacity: 0.3;
  }
  &__disabled-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    cursor: not-allowed;
    background: rgba(128, 128, 128, 0.137);

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    z-index: 9999999999;
  }
  &.v-tour__target--highlighted {
    box-shadow: 0 3px 20px rgba(26, 131, 201, 0.418);
  }
  &__header {
    @apply pt-6 pb-4 px-8;
    &__top {
      @apply flex justify-between flex-wrap;
      &__left {
        @apply flex w-full items-end flex-wrap mb-9 justify-between;
        h4 {
          @apply mb-3;
          margin-right: 30px;
        }
      }
      &__right {
        @apply flex items-center;
        a {
          @apply mx-4;
          svg {
            width: 1.5em;
            height: 1.5em;
          }
        }
      }
    }
  }
  &__description {
    @apply font-medium text-sm my-4;
    color: $color-grey;
  }
  &__tags {
    @apply flex items-center px-8 flex-wrap;
    & > div {
      @apply mb-4;
      &:first-child {
        @apply mr-4;
      }
    }
  }
  &__content {
    @apply px-8 pt-3 pb-5 w-full max-w-full;
    min-height: 150px;
  }
  &__footer {
    @apply py-6 px-8;
    min-height: 95px;
    margin-top: auto;
    background-color: #f4f6fc;
  }
  @screen lg {
    &__tags {
      & > div {
        @apply mb-2;
      }
    }
    &__header {
      &__top {
        &__left {
          @apply mb-0 justify-start items-center;
          width: 50%;
          h4 {
            @apply mb-0;
          }
          &--full {
            @apply w-full justify-between;
            h4 {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
.comments {
  ::v-deep {
    .mention {
      background-color: transparent;
      @apply text-blue-500 font-semibold;
    }
  }
  .message-box {
    max-height: 100%;
    ::v-deep {
      p {
        max-width: 100%;
        word-break: break-word;
      }
      a {
        @apply text-blue-500;
      }
    }
  }
}
.editor {
  @apply max-w-full;
  ::v-deep {
    .ql-container {
      border-width: 0px;
      font-size: inherit;
      font-family: inherit;
      .ql-editor {
        max-height: 100px;
        overflow: scroll;
      }
    }
    .mention {
      @apply font-bold bg-transparent;
    }
    .ql-mention-list {
      @apply overflow-y-scroll;
      max-height: 300px;
    }
  }
}
</style>
